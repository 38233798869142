import React from 'react';
import { Link, navigate } from 'gatsby';
import { isLoggedIn, logout, getCurrentUser } from '../../../services/auth';
import './navbar.scss';
import classNames from 'classnames';

export default function NavBar({ location }) {
  const search = location?.href;
  const type = search?.split('/')?.[4];
  const navBarClass = classNames({
    'navbar-container': true,
    hidden: type === 'registration-confirmation' || type === 'login' || type === 'register',
    white: type === 'account',
  });

  return (
    <div className={navBarClass}>
      <div className="container wrapper">
        <Link to={isLoggedIn() ? `/${getCurrentUser()?.type}/account` : '/'} className="logo">
          <span className="title bold">Contacts</span>
          <span className="title purple">Portal</span>
        </Link>

        <nav>
          <ul className="navbar-list">
            {isLoggedIn() && (
              <li className="navbar-btn">
                <Link to={`/${getCurrentUser().type}/account`} className="title">
                  Account
                </Link>
              </li>
            )}

            {/* <li className="item">
              <Link to="/contact" className="title">
                Help
              </Link>
            </li>

            <li className="item">
              <Link to="/" className="title">
                About
              </Link>
            </li> */}

            {!isLoggedIn() && (
              <li className="item">
                <Link to="/customer/login" className="title">
                  Login
                </Link>
              </li>
            )}

            <li className="item">
              {isLoggedIn() ? (
                <a
                  className="title"
                  href="/"
                  onClick={(event) => {
                    event.preventDefault();
                    logout(() => navigate(`/`));
                  }}
                >
                  Logout
                </a>
              ) : null}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
