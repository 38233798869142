import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NavBar from './navbar/navbar';
import WrapperLogo from './wrapperLogo/WrapperLogo';
import Footer from './footer/Footer';
import WrapperFooter from './footer/wrapperFooter/WrapperFooter';
import AccountNav from './account/AccountNav';
import PatientsNav from './account/PatientsNav';
import RoundButton from '../shared/button/roundButton/RoundButton';
import './Layout.scss';
import { getUser } from '../../services/auth';
import PatientContext from '../../context/PatientContext';
import { getCustomers } from '../../services/requests';

const Layout = ({ children, location }) => {
  const [isPatient, setIsPatient] = useState(false);
  const [isAccount, setIsAccount] = useState(false);
  const [isDoctor, setIsDoctor] = useState(false);
  const [activeLogo, setActiveLogo] = useState(false);
  const [doctor, setDoctor] = useState({});
  const [order, setOrder] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [backRef, setBackRef] = useState('');

  const { setPatient, patient } = useContext(PatientContext);

  const handleEditing = () => {
    localStorage.setItem('order', JSON.stringify({ ...order, isEditing: false }));
  };

  const getPatientName = () => {
    return `${order?.patient?.first_name} ${order?.patient?.last_name}`;
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const tempOrder = JSON.parse(localStorage.getItem('order'));
    const item = tempOrder?.item;
    const search = location?.pathname?.split('/');
    const user = getUser();
    const typeOfUser = user?.type;

    setBackRef(`${search.slice(0, 4).join('/')}/${item?.title}/summary`);
    setOrder(tempOrder);
    setIsEditing(tempOrder?.isEditing);
    setIsConfirmation(search.includes('doctor') && search.includes('confirmation'));

    setIsAccount(search[2] === 'account');
    setActiveLogo(search[2] === 'account' || search[2] === 'register');

    if (typeOfUser === 'customer') {
      setIsPatient(search[2] === 'account');
      setPatient(user);
      return;
    }

    const patientId = search[3] === 'patients' && Number(search[4]);

    setDoctor(JSON.parse(localStorage.getItem('gatsbyUser')));
    setIsPatient(search[3] === 'patients' && patientId);
    setIsDoctor(typeOfUser === 'doctor' && search[2] === 'account' && !patientId);

    if (patientId) {
      getCustomers().then(({ data }) => {
        setPatient(data.find((item) => item?.id === patientId));
      });
    } else if (patient) {
      setPatient({});
    }
  }, [location]);

  return (
    typeof window !== 'undefined' && (
      <>
        {isAccount ? <WrapperLogo active={activeLogo} /> : <NavBar location={location} />}
        {isPatient ? <PatientsNav /> : null}
        {isDoctor && !isEditing && !isConfirmation ? <AccountNav doctor={doctor} /> : null}
        {isDoctor && isEditing ? (
          <div className="flex-box container name-container">
            <RoundButton onClick={handleEditing} redirectTo={backRef} state={{}} />
            <span className="title head1 extrabold">{getPatientName()}</span>
          </div>
        ) : null}
        <div className="main-content-wrapper">
          <main>{children}</main>
        </div>
        {isAccount ? <WrapperFooter /> : <Footer location={location} />}
        <ToastContainer position="top-center" autoClose={5000} pauseOnHover={false} />
      </>
    )
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
