import { isEmpty } from 'lodash';
import React, { createContext, useState, useEffect } from 'react';

import { getLocalStorage } from '../services/utils';

const DoctorContext = createContext({
  doctor: {},
  setDoctor: () => {},
});

export const DoctorContextProvider = ({ children }) => {
  const [doctor, setDoctor] = useState({});

  useEffect(() => {
    const user = getLocalStorage('gatsbyUser');

    if (user?.type !== 'doctor') return;

    if (!isEmpty(doctor)) setDoctor(user);
  }, []);

  const setContextDoctor = (obj) => {
    setDoctor(obj);
    localStorage.setItem('gatsbyUser', JSON.stringify(obj));
  };

  return (
    <DoctorContext.Provider value={{ doctor, setContextDoctor }}>{children}</DoctorContext.Provider>
  );
};

export default DoctorContext;
