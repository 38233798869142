export const getDate = (date) => {
  const formattedDate = new Date(date);
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return formattedDate.toLocaleDateString('en-US', options);
};

export const calculatePagination = (items, itemsPerPage, currentPage) => {
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  return items.slice(indexOfFirstItem, indexOfLastItem);
};

export const getUpcomingOrder = (array, key) => {
  const latestDate = new Date(Math.max(...array.map((item) => new Date(item[key]))));

  return array.find((item) => new Date(item[key]).getTime() === latestDate.getTime());
};

export const getLatestAddress = (addresses) => {
  return addresses.reduce(
    (latest, address) => (address?.id > latest?.id ? address : latest),
    addresses[0],
  );
};

export const getOrderPrescription = (order, param) => {
  return order?.order_items
    ?.reduce(
      (result, item) => [
        ...result,
        `${item?.eye_side?.[0]?.toUpperCase()}: ${
          param === 'quantity' ? item?.quantity : item?.product_variant?.[param]
        }`,
      ],
      [],
    )
    .join(', ');
};

export const getClientOrderPrescription = (order, param) => {
  return Object.entries(order)
    ?.reduce((result, [key, item]) => [...result, `${key[0].toUpperCase()}: ${item[param]}`], [])
    .join(', ');
};

export const handleShowModal = (obj, setShowModal) => {
  setShowModal((prevState) => ({ ...prevState, ...obj }));
};

export const getSessionStorage = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
};

export const getLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};
