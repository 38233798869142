import React, { createContext, useState, useEffect } from 'react';

const PatientContext = createContext({
  patient: {},
  setPatient: () => {},
});

export const PatientContextProvider = ({ children }) => {
  const [patient, setContextPatient] = useState({});

  useEffect(() => {
    const tempPatient = getStorage('patient');

    setContextPatient(tempPatient || {});
  }, []);

  const getStorage = (key) => {
    return JSON.parse(sessionStorage.getItem(key));
  };

  const setPatient = (obj) => {
    setContextPatient(obj);
    sessionStorage.setItem('patient', JSON.stringify(obj));
  };

  return (
    <PatientContext.Provider value={{ patient, setPatient }}>{children}</PatientContext.Provider>
  );
};

export default PatientContext;
