import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import RoundButton from '../../shared/button/roundButton/RoundButton';
import NavButton from '../../shared/button/navButton/NavButton';
import PatientContext from '../../../context/PatientContext';
import { getUser } from '../../../services/auth';
import './AccountNav.scss';

const PatientsNav = () => {
  const [currPatient, setCurrPatient] = useState();
  const [userRole, setUserRole] = useState();
  const user = getUser();

  const onBack = () => {
    navigate('/doctor/account/patients/');
  };

  const { patient } = useContext(PatientContext);

  useEffect(() => {
    const tempPatient = user?.type === 'doctor' ? patient : user;

    if (!tempPatient) return;

    setCurrPatient(patient);
    setUserRole(user?.type);
  }, [patient]);

  return (
    <>
      {currPatient && Object.keys(currPatient).length !== 0 && (
        <div className="account-nav-container tile-container">
          <div className="name-container">
            {userRole === 'doctor' && <RoundButton onClick={onBack} />}
            <span className="title head1 extrabold">{`${currPatient?.first_name} ${currPatient?.last_name}`}</span>
          </div>
          <div className="nav-buttons">
            <NavButton
              label="Orders"
              redirectTo={
                userRole === 'doctor'
                  ? `/doctor/account/patients/${currPatient?.id}/orders`
                  : `/customer/account/orders`
              }
            />
            <NavButton
              label="Subscription"
              redirectTo={
                userRole === 'doctor'
                  ? `/doctor/account/patients/${patient?.id}/subscription`
                  : `/customer/account/patients/${patient?.id}/subscription`
              }
            />
            <NavButton
              label="Account Info"
              redirectTo={
                userRole === 'doctor'
                  ? `/doctor/account/patients/${currPatient?.id}/account-info`
                  : `/customer/account/account-info`
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PatientsNav;
