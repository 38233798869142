import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import facebookIcon from '../../mainPage/img/facebook.png';
import twitterIcon from '../../mainPage/img/twitter.png';
import youtubeIcon from '../../mainPage/img/youtube.png';
import instagramIcon from '../../mainPage/img/instagram.png';
import pinterestIcon from '../../mainPage/img/pinterest.png';
import PrimaryButton from '../../shared/button/PrimaryButton';
import CustomInput from '../../shared/input/customInput/CustomInput';
import { isLoggedIn, getUser, getCurrentUser } from '../../../services/auth';
import './Footer.scss';

const Footer = ({ location }) => {
  const search = location?.href;
  const type = search?.split('/')?.[4];

  const footerClass = classNames({
    'footer-bg': true,
    hidden:
      type === 'login' ||
      type === 'account' ||
      type === 'registration-confirmation' ||
      type === 'register',
  });

  return (
    <>
      <div className={footerClass}>
        <div className="container">
          <div className="row footer-container">
            <div className="footer-button-container">
              <PrimaryButton
                label="Get Started"
                redirectTo={isLoggedIn() ? `/${getCurrentUser()?.type}/account` : '/doctor/login'}
                gray
                small
              />
            </div>
            <div className="info-container">
              <div>
                <div>
                  <p className="slogan">
                    <span className="font-weight-bold">Contacts</span>
                    <span className="color-violet">Portal</span>
                  </p>
                </div>
                <div className="icons-container">
                  <div>
                    <Link to="#">
                      <img alt="#" src={facebookIcon} />
                    </Link>
                  </div>
                  <div>
                    <Link to="#">
                      <img alt="#" src={twitterIcon} />
                    </Link>
                  </div>
                  <div>
                    <Link to="#">
                      <img alt="#" src={youtubeIcon} />
                    </Link>
                  </div>
                  <div>
                    <Link to="#">
                      <img alt="#" src={instagramIcon} />
                    </Link>
                  </div>
                  <div>
                    <Link to="#">
                      <img alt="#" src={pinterestIcon} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="link-container half-width">
                <p className="link-name">Shop</p>
                <Link to="#" className="link">
                  <p>Find Prescribed Brand</p>
                </Link>
                <Link to="#" className="link">
                  <p>About</p>
                </Link>
                {isLoggedIn() && (
                  <Link to={`${getUser()?.type}/account`} className="link">
                    <p>Account</p>
                  </Link>
                )}
              </div>
              <div className="link-container half-width">
                <p className="link-name">Customer Care</p>
                <Link to="#" className="link">
                  <p>Privacy Policy</p>
                </Link>
                <Link to="#" className="link">
                  <p>Terms of Service</p>
                </Link>
                <Link to="#" className="link">
                  <p>Accessibility Policy</p>
                </Link>
                <Link to="#" className="link">
                  <p>FAQ</p>
                </Link>
              </div>
              <div className="link-container">
                <p className="link-name">Newsletter</p>
                <CustomInput />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="bottom-container">
              <div>
                <Link to="#" className="link">
                  <span>
                    <span className="me-1">&#169;</span>Contacts Portal
                  </span>
                </Link>
              </div>
              {/* <div>
                <Link to="/contact" className="link">
                  <span>Contact</span>
                </Link>
                <Link to="#" className="link ms-5">
                  <span>Help</span>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
