import React from 'react';
import arrowIcon from '../../../mainPage/img/arrow.png';
import './CustomInput.scss';

const CustomInput = () => {
  return (
    <div>
      <input type="text" className="custom-input" placeholder="Your email" />
      <img alt="" src={arrowIcon} />
    </div>
  );
};

export default CustomInput;
