import React from 'react';
import { Link } from 'gatsby';
import './NavButton.scss';

const NavButton = ({ redirectTo, state, label }) => {
  return (
    <div className="navigation-btn-container">
      <Link
        to={redirectTo}
        state={state}
        className="navigation-btn"
        activeClassName="active"
        partiallyActive
      >
        {label}
      </Link>
    </div>
  );
};

export default NavButton;
