import React from 'react';
import { Link } from 'gatsby';
import './RoundButton.scss';

const RoundButton = ({ redirectTo, state, onClick }) => {
  return (
    <div tabIndex={0} role="button" className="round-button-container" onClick={onClick}>
      {redirectTo ? (
        <Link state={state} to={redirectTo} className="round-button">
          <span></span>
        </Link>
      ) : (
        <button className="round-button">
          <span></span>
        </button>
      )}
    </div>
  );
};

export default RoundButton;
