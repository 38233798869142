import React, { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import DoctorContext from '../../../context/DoctorContext';
import NavButton from '../../shared/button/navButton/NavButton';
import './AccountNav.scss';

const AccountNav = ({}) => {
  const [currentDoctor, setCurrentDoctor] = useState({});
  const { doctor } = useContext(DoctorContext);

  useEffect(() => {
    isEmpty(doctor)
      ? setCurrentDoctor(JSON.parse(localStorage.getItem('gatsbyUser')))
      : setCurrentDoctor(doctor);
  }, [doctor]);

  return (
    <div className="account-nav-container container">
      <div className="name-container">
        <span className="title head1 extrabold">Dr.{currentDoctor?.last_name}</span>
      </div>
      <div className="nav-buttons">
        {(currentDoctor?.kyc_requirements?.length === 0 ||
          (currentDoctor?.kyc_requirements?.length === 1 &&
            currentDoctor?.kyc_requirements[0] === 'external_account')) && (
          <>
            <NavButton label="Products" redirectTo={`/doctor/account/collections`} />
            <NavButton label="Patients" redirectTo={`/doctor/account/patients`} />
            <NavButton label="Orders" redirectTo={`/doctor/account/orders`} />
            <NavButton label="Payouts" redirectTo={`/doctor/account/payouts`} />
          </>
        )}
        <NavButton label="Account Info" redirectTo={`/doctor/account/info`} />
      </div>
    </div>
  );
};

export default AccountNav;
